<template>
	<SearchResultListItem
		:ref="CAROUSEL_ITEM"
		:result="result"
		:is-mobile="isMobile"
		:list-type="result.listType"
	>
		<template #row2>
			<span
				:class="[
					{'text-sm': isMobile},
					'mr-2 truncate capitalize'
				]"
			>
				{{ result.productType }}
			</span>
			<span	class="mr-2 text-gray-300">
				•
			</span>
			<span
				:class="[
					{'text-sm': isMobile},
					'truncate'
				]"
			>
				{{ result.indication }}
			</span>
		</template>

		<template #row3>
			<div
				:class="[
					{'text-sm': isMobile},
					'flex items-end justify-between text-left'
				]"
			>
				<span class="w-3/5 mr-2 truncate">
					by
					<span class="font-bold capitalize">
						&nbsp; {{ result.businessName }}
					</span>
				</span>
				<span class="flex truncate">
					<img
						:src="result.icon"
						alt="Category Icon"
						class="mr-1"
						width="17"
						height="17"
					>
					{{ result.claimCount }}
				</span>
			</div>
		</template>
	</SearchResultListItem>
</template>

<script async>
import SearchResultListItem from '@/components/search/SearchResultListItem.vue'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'

export default {
	components: {
		SearchResultListItem
	},
	props: {
		result: {
			type: Object,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		compact: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			CAROUSEL_ITEM
		}
	}
}
</script>
