var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"group shrink-0",attrs:{"to":_vm.category.link}},[_c('div',{ref:_vm.CAROUSEL_ITEM,staticClass:"space-y-3"},[_c('div',{class:[
				{ 'w-32 md:w-auto': _vm.showSquareCard },
				'border border-gray-300 shadow-lg cursor-pointer group-hover:shadow-xl rounded-xl'
			]},[_c('img',{class:[
					{'h-36 md:h-auto': _vm.showRectangleCard},
					'object-cover rounded-xl'
				],attrs:{"src":_vm.category.image,"alt":`${_vm.category.label}-image`,"height":_vm.height,"width":_vm.width}})]),_vm._v(" "),_c('div',{staticClass:"mx-0 font-bold text-center"},[_vm._v("\n\t\t\t"+_vm._s(_vm.category.label)+"\n\t\t")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }