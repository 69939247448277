<template>
	<router-link
		:to="category.link"
		class="group shrink-0"
	>
		<div
			:ref="CAROUSEL_ITEM"
			class="space-y-3"
		>
			<div
				:class="[
					{ 'w-32 md:w-auto': showSquareCard },
					'border border-gray-300 shadow-lg cursor-pointer group-hover:shadow-xl rounded-xl'
				]"
			>
				<img
					:src="category.image"
					:alt="`${category.label}-image`"
					:height="height"
					:width="width"
					:class="[
						{'h-36 md:h-auto': showRectangleCard},
						'object-cover rounded-xl'
					]"
				>
			</div>
			<div class="mx-0 font-bold text-center">
				{{ category.label }}
			</div>
		</div>
	</router-link>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'

const DIMENSION = 170

export default {
	props: {
		category: {
			type: Object,
			required: true
		},
		rectangle: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			CAROUSEL_ITEM,
			height: DIMENSION,
			width: DIMENSION
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		showRectangleCard() {
			return this.rectangle
		},
		showSquareCard() {
			return !this.rectangle
		}
	},
	created() {
		this.pushMetaLink({ rel: 'preload', href: this.category.image, as: 'image' })
	},
	methods: {
		...mapMutations([ 'pushMetaLink' ])
	}
}
</script>
