var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SearchResultListItem',{ref:_vm.CAROUSEL_ITEM,attrs:{"result":_vm.result,"is-mobile":_vm.isMobile,"list-type":_vm.result.listType},scopedSlots:_vm._u([{key:"row2",fn:function(){return [_c('span',{class:[
				{'text-sm': _vm.isMobile},
				'mr-2 truncate capitalize'
			]},[_vm._v("\n\t\t\t"+_vm._s(_vm.result.productType)+"\n\t\t")]),_vm._v(" "),_c('span',{staticClass:"mr-2 text-gray-300"},[_vm._v("\n\t\t\t•\n\t\t")]),_vm._v(" "),_c('span',{class:[
				{'text-sm': _vm.isMobile},
				'truncate'
			]},[_vm._v("\n\t\t\t"+_vm._s(_vm.result.indication)+"\n\t\t")])]},proxy:true},{key:"row3",fn:function(){return [_c('div',{class:[
				{'text-sm': _vm.isMobile},
				'flex items-end justify-between text-left'
			]},[_c('span',{staticClass:"w-3/5 mr-2 truncate"},[_vm._v("\n\t\t\t\tby\n\t\t\t\t"),_c('span',{staticClass:"font-bold capitalize"},[_vm._v("\n\t\t\t\t\t  "+_vm._s(_vm.result.businessName)+"\n\t\t\t\t")])]),_vm._v(" "),_c('span',{staticClass:"flex truncate"},[_c('img',{staticClass:"mr-1",attrs:{"src":_vm.result.icon,"alt":"Category Icon","width":"17","height":"17"}}),_vm._v("\n\t\t\t\t"+_vm._s(_vm.result.claimCount)+"\n\t\t\t")])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }