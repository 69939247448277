<template>
	<SearchResultListItem
		:ref="CAROUSEL_ITEM"
		:result="result"
		:is-mobile="isMobile"
		:list-type="computedListType"
		:img-alt="imgAlt"
		:is-autocompleter="isAutocompleter"
	>
		<template #row3>
			<div
				v-if="result.description"
				:class="[
					{'text-sm': isMobile},
					'text-left'
				]"
			>
				<div
					class="leading-5"
					:class="{ 'md:leading-6': !isMobile }"
					v-html="result.description"
				/>
			</div>
		</template>
	</SearchResultListItem>
</template>

<script async>
import SearchResultListItem from '@/components/search/SearchResultListItem.vue'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'

export default {
	components: {
		SearchResultListItem
	},
	props: {
		result: {
			type: Object,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		isAutocompleter: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			CAROUSEL_ITEM
		}
	},
	computed: {
		computedListType () {
			return this.result?.listType || this.listType
		},
		imgAlt() {
			return this.result?.title || ' '
		}
	}
}
</script>
