import { BLOG_IMAGES, LISTING_IMAGES, MENU_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_LEAF_IMAGE, DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import {
	BLOGS_LIST,
	BUSINESSES_LIST,
	DEALS_LIST,
	LOCATIONS_LIST,
	MENUS_LIST,
	STRAINS_LIST_COMPACT,
	STRAINS_LIST_FULL
} from '@/constants/search/listTypes.js'
import {
	BLOG,
	BUSINESS,
	CBD,
	CURRENT_LOCATION,
	DEAL,
	DOCTOR,
	LOCATION,
	MENU,
	STRAIN
} from '@/constants/search/resultTypes.js'

const parseImageFilename = (image, businessSubType) => {
	let defaultImage = null
	if (businessSubType && businessSubType.length) {
		switch (businessSubType) {
			case BUSINESS:
				defaultImage = DEFAULT_WW_GRAY_LOGO
				break
			case CBD:
				defaultImage = DEFAULT_WW_GRAY_LOGO
				break
			case DOCTOR:
				defaultImage = DEFAULT_WW_GRAY_LOGO
				break
			default:
				defaultImage = DEFAULT_LEAF_IMAGE
				break
		}
	} else {
		defaultImage = DEFAULT_WW_GRAY_LOGO
	}

	if (typeof image === 'string' && !image.length) return defaultImage
	if (typeof image !== 'string') {
		return defaultImage
	}
	return image?.split('/').pop()
}

const getBusinessIconType = (businessSubType) => {
	switch (businessSubType) {
		case BUSINESS:
			return require('@/assets/icons/shop.svg')
		case CBD:
			return require('@/assets/icons/cbd-stores.svg')
		case DOCTOR:
			return require('@/assets/icons/doctors-icon.svg')
		default:
			return require('@/assets/icons/shop.svg')
	}
}

const getBusinessCategory = (businessSubType) => {
	switch (businessSubType) {
		case BUSINESS:
			return 'Business'
		case CBD:
			return 'CBD Store'
		case DOCTOR:
			return 'Doctor'
		default:
			return 'Business'
	}
}

const destructureSearchResult = function (result, LIST_TYPE, businessSubType) {
	const {
		name: title2,
		title,
		seo: link,
		url: link2,
		image,
		rating,
		date,
		category,
		category_name: categoryName,
		productType,
		indication,
		businessName,
		claimCount,
		review_average: reviewAverage,
		review_count: reviewCount2,
		reviewCount,
		type: strainType,
		description,
		city,
		state_short: state,
		businessCount,
		id,
		has_ordering: hasOrdering
	} = result

	const hasOrdering2 = result?.settings?.hasOrdering || result?.hasOrdering || false
	const cityName = result?.address?.city || city
	const stateShort = result?.address?.state?.short || state
	const ratingOverall = result?.ratingOverall
	const resultImage = result?.logo?.fileName || image

	switch (LIST_TYPE) {
		// STRAIN is only used for /strains page (has rating data). Will be used for city page row component as well.
		case STRAINS_LIST_FULL:
			return {
				title: title || title2,
				link: link2 || '/strains/' + link,
				id,
				image: parseImageFilename(resultImage),
				imagePath: MENU_IMAGES,
				rating,
				category: STRAIN,
				strainType: strainType || description || '',
				listType: LIST_TYPE,
				businessCount,
				icon: require('@/assets/icons/strain.svg')
			}
		// #REVIEW these _LIST cases are used differentiate from search page card data and search dropdown result data
		case STRAINS_LIST_COMPACT:
			return {
				title: title || title2,
				link: link2,
				image: parseImageFilename(resultImage),
				imagePath: MENU_IMAGES,
				category: STRAIN,
				strainType: description,
				listType: LIST_TYPE,
				icon: require('@/assets/icons/strain.svg')
			}
		case MENUS_LIST:
			return {
				title: title || title2,
				link: link2,
				description: description.split('menu ').pop(),
				category: MENU,
				listType: LIST_TYPE,
				image: parseImageFilename(resultImage),
				imagePath: LISTING_IMAGES,
				icon: require('@/assets/icons/product-new.svg')
			}
		case BUSINESSES_LIST:
			return {
				title: title && title.trim() || title2 && title2.trim(),
				link: link2,
				category: getBusinessCategory(businessSubType),
				listType: LIST_TYPE,
				rating: reviewAverage || rating || ratingOverall,
				reviewCount: reviewCount || reviewCount2,
				description: description && description.length
					? `
				<div class="truncate w-full">
					${description?.replace(/, DC/g, '')}
				</div>`
					: `
				<span class="w-full flex items-center">
					<img
						src="${require('@/assets/icons/pin.svg')}"
						class="mr-2"
						width="18"
						height="18"
						alt="location pin icon"
					>
					<span class="line-clamp-1">
						${(cityName + ', ' + stateShort).replace(/, DC/g, '')}
					</span>
				</span>`,
				image: parseImageFilename(resultImage, businessSubType),
				imagePath: LISTING_IMAGES,
				icon: getBusinessIconType(businessSubType),
				hasOrdering: hasOrdering || hasOrdering2
			}
		case BLOGS_LIST:
			return {
				title: title || title2,
				link: link2,
				image: resultImage.fileName || image || DEFAULT_LEAF_IMAGE,
				imagePath: BLOG_IMAGES,
				category: BLOG,
				blogCategory: categoryName || category[0].name,
				date,
				listType: LIST_TYPE,
				icon: require('@/assets/icons/study-owl.svg')
			}
		case DEALS_LIST:
			return {
				title: title || title2,
				link: link2,
				image: resultImage || DEFAULT_LEAF_IMAGE,
				imagePath: MENU_IMAGES,
				category: DEAL,
				productType,
				indication,
				businessName,
				claimCount,
				listType: LIST_TYPE,
				icon: require('@/assets/icons/person.svg')
			}
		// LOCATIONS_LIST is not used right now. It would show on the search results page if enabled.
		case LOCATIONS_LIST:
			return {
				title: title || title2,
				link: link2,
				category: LOCATION,
				listType: LIST_TYPE,
				image: resultImage || DEFAULT_LEAF_IMAGE,
				icon: require('@/assets/icons/pin.svg')
			}
		// LOCATION is the global search result
		case LOCATION:
			return {
				title: title || title2,
				link: link2,
				category: LOCATION,
				listType: LIST_TYPE,
				icon: require('@/assets/icons/pin.svg')
			}
		case CURRENT_LOCATION:
			return {
				listType: CURRENT_LOCATION,
				icon: require('@/assets/icons/gps.svg')
			}
		default:
			return {}
	}
}

export default destructureSearchResult
