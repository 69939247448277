<template>
	<div
		:class="[
			'w-full force-w-screen',
			bgColor
		]"
	>
		<div
			:class="[
				'w-full max-w-6xl xl:px-3 mx-auto',
				{'border-b border-gray-200': showBottomBorder}
			]"
		>
			<WwCarousel
				:id="listType"
				:key="listType"
				:item-count="itemCount"
				:items-per-slide="6"
				item-spacing="space-x-3 xl:space-x-4"
				side-scroller-class="px-3 xl:px-1.5 pb-8"
				scrollbar-visibility="xl:hide-horizontal-scrollbar"
				arrow-visibility="hidden xl:block"
				fade-visibility="hidden xl:block"
				:gradient-color-from="fadeColorFrom"
				:arrow-offset-classes="arrowOffsetClasses"
				hide-footer
				:loading="loading"
			>
				<template #header>
					<div class="w-full px-3 py-4 xl:px-0 flex-nowrap">
						<h2 class="text-xl font-bold text-left uppercase truncate xl:text-2xl">
							{{ heading }}
						</h2>
						<router-link
							v-if="url.length"
							tag="button"
							class="flex w-auto text-sm font-bold text-left text-green-500 uppercase xl:text-base hover:text-purple-500"
							:to="url"
							:title="`View All ${heading}`"
						>
							View All
						</router-link>
						<h5
							v-if="subHeading"
							class="py-1 text-lg text-gray-500"
						>
							{{ subHeading }}
						</h5>
					</div>
				</template>
				<template #slides>
					<template v-if="listType === BLOGS_LIST">
						<SearchCardBlog
							v-for="( card, index ) in destructuredItems"
							:key="`${listType}-card-${index}`"
							:result="card"
							:is-mobile="false"
							:list-type="listType"
						/>
					</template>
					<template v-if="listType === BUSINESSES_LIST">
						<SearchCardBusiness
							v-for="( card, index ) in destructuredItems"
							:key="`${listType}-card-${index}`"
							:result="card"
							:is-mobile="false"
							:list-type="listType"
						/>
					</template>
					<template v-if="listType === DEALS_LIST">
						<SearchCardDeal
							v-for="( card, index ) in destructuredItems"
							:key="`${listType}-card-${index}`"
							:result="card"
							:is-mobile="false"
							:list-type="card.listType"
						/>
					</template>
					<template v-if="listType === STRAINS_LIST_FULL">
						<SearchCardStrain
							v-for="( card, index ) in destructuredItems"
							:key="`${listType}-card-${index}`"
							:result="card"
							:is-mobile="false"
							:list-type="card.listType"
						/>
					</template>
					<template v-if="listType === CATEGORY_LIST || listType === BRANDS_LIST">
						<WwCategoryCard
							v-for="( card, index ) in items"
							:key="`${listType}-card-${index}`"
							:category="card"
							:rectangle="listType === BRANDS_LIST"
						/>
					</template>
				</template>
			</WwCarousel>
		</div>
	</div>
</template>

<script async>
import destructureSearchResult from '@/api/helpers/destructureSearchResult.js'
import SearchCardBlog from '@/components/search/SearchCardBlog.vue'
import SearchCardBusiness from '@/components/search/SearchCardBusiness.vue'
import SearchCardDeal from '@/components/search/SearchCardDeal.vue'
import SearchCardStrain from '@/components/search/SearchCardStrain.vue'
import WwCarousel from '@/components/UI/WwCarousel.vue'
import WwCategoryCard from '@/components/UI/WwCategoryCard.vue'
import {
	BLOGS_LIST,
	BRANDS_LIST,
	BUSINESSES_LIST,
	CATEGORY_LIST,
	DEALS_LIST,
	MENUS_LIST,
	STRAINS_LIST,
	STRAINS_LIST_FULL
} from '@/constants/search/listTypes.js'
import { BUSINESS, MENU, STRAIN } from '@/constants/search/resultTypes.js'

export default {
	components: {
		WwCarousel,
		SearchCardBusiness,
		SearchCardDeal,
		SearchCardBlog,
		SearchCardStrain,
		WwCategoryCard
	},
	props: {
		items: {
			type: Array,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		businessSubType: {
			type: String,
			default: BUSINESS
		},
		url: {
			type: String,
			default: ''
		},
		heading: {
			type: String,
			required: true
		},
		itemCount: {
			type: Number,
			required: true
		},
		bgColor: {
			type: String,
			default: 'bg-gray-100'
		},
		fadeColorFrom: {
			type: String,
			default: 'from-gray-100'
		},
		arrowOffsetClasses: {
			type: String,
			default: ''
		},
		subHeading: {
			type: String,
			default: ''
		},
		showBottomBorder: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			STRAIN,
			STRAINS_LIST,
			STRAINS_LIST_FULL,
			BUSINESSES_LIST,
			BLOGS_LIST,
			MENUS_LIST,
			DEALS_LIST,
			CATEGORY_LIST,
			BRANDS_LIST,
			isMounted: false
		}
	},
	computed: {
		destructuredItems() {
			const tempArray = []

			if (this.items && this.items.length) {
				for (const index in this.items) {
					const tempItem = this.items[index]
					if (tempItem) {
						const tempCategory = this.setResultListType(this.listType, tempItem.type)
						tempArray.push(destructureSearchResult(tempItem, tempCategory, this.businessSubType))
					}
				}
				return tempArray
			} else {
				return []
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		setResultListType(listType) {
			switch (listType) {
				case MENUS_LIST || MENU:
					return MENUS_LIST
				case STRAINS_LIST || STRAIN:
					return STRAINS_LIST_FULL
				case BUSINESSES_LIST:
					return BUSINESSES_LIST
				case BLOGS_LIST:
					return BLOGS_LIST
				case DEALS_LIST:
					return DEALS_LIST
				case CATEGORY_LIST:
					return CATEGORY_LIST
				default:
					return STRAINS_LIST_FULL
			}
		}
	}
}
</script>
